import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./treatments.css"
export default class TreatmentPage extends Component {
  render() {
    return (
      <Layout>
        <SEO title="Price" />
        <div className="pricelist-container">
          <h1 className="pricelist-header">Price List</h1>
          <p className="pricelist-header">Gua Sha</p>
          <div className="pricelist-details">
            <p className="pricelist-details-left">30 minutes (Face)</p>
            <p className="pricelist-details-right">£30</p>
          </div>
          <div className="pricelist-details">
            <p className="pricelist-details-left">60 minutes (Full body)</p>
            <p className="pricelist-details-right">£60</p>
          </div>

          <p className="pricelist-header">Acupuncture</p>
          <div className="pricelist-details">
            <p className="pricelist-details-left">30 minutes</p>
            <p className="pricelist-details-right">£30</p>
          </div>

          <p className="pricelist-header">Acupuncture and moxibustion</p>
          <div className="pricelist-details">
            <p className="pricelist-details-left">60 minutes</p>
            <p className="pricelist-details-right">£90</p>
          </div>

          <p className="pricelist-header">Therapeutic massage</p>
          <div className="pricelist-details">
            <p className="pricelist-details-left">30 minutes</p>
            <p className="pricelist-details-right">£30</p>
          </div>
          <div className="pricelist-details">
            <p className="pricelist-details-left">60 minutes</p>
            <p className="pricelist-details-right">£50</p>
          </div>

          <p className="pricelist-header">Therapeutic massage and moxibustion</p>
          <div className="pricelist-details">
            <p className="pricelist-details-left">60 minutes</p>
            <p className="pricelist-details-right">£90</p>
          </div>

          <p className="pricelist-header">Reflexology</p>
          <div className="pricelist-details">
            <p className="pricelist-details-left">30 minutes</p>
            <p className="pricelist-details-right">£30</p>
          </div>

          <p className="pricelist-header">Facial beauty massage</p>
          <div className="pricelist-details">
            <p className="pricelist-details-left">30 minutes</p>
            <p className="pricelist-details-right">£30</p>
          </div>
          <div className="pricelist-details">
            <p className="pricelist-details-left">60 minutes</p>
            <p className="pricelist-details-right">£60</p>
          </div>

          <p className="pricelist-header">Cupping</p>
          <div className="pricelist-details">
            <p className="pricelist-details-left">15 minutes</p>
            <p className="pricelist-details-right">£15</p>
          </div>

          <h3 className="pricelist-header-offers">Offers</h3>
          <p>Buy any 5 sessions of treatment and receive 1 free</p>
          <p>Buy any 8 sessions of treatment and receive 2 free</p>
          <p>Buy any 10 sessions of treatment and receive 3 free</p>
        </div>
      </Layout>
    )
  }
}
